<!-- 安全中心--设置密码--结果 第三步-->
<template>
  <div class="verifysetresult">
    <div class="top">
      <el-row class='head'>
        <el-col :span="12">
          <logotitle :title='["设置支付密码","修改支付密码","修改登录密码","找回密码"][this._spf(this.$route.query.types)]'></logotitle>
        </el-col>
        <el-col :span="12">
        </el-col>
      </el-row>
    </div>
    <div class="stepcnt">
      <verifystep class='verifystep' :steps='2'></verifystep>
      <div class="verifybox pas">
        <div class="icon">
          <span><svg><use xlink:href="#iconwancheng"></use></svg></span>
          <h6>{{["设置支付密码","修改支付密码","修改登录密码","找回密码"][this._spf(this.$route.query.types)]+'成功！'}}</h6>
        </div>
        <template v-if='this._spf(this.$route.query.types)==0'>
          <button  class="next" @click='linkSafe'>返回安全中心</button>
        </template>
        <template v-if='this._spf(this.$route.query.types)==1'>
          <button  class="next" @click='linkSafe'>返回安全中心</button>
        </template>
        <template v-if='this._spf(this.$route.query.types)==2'>
          <button  class="next" @click='linkSafe'>返回安全中心</button>
        </template>
        <template v-if='this._spf(this.$route.query.types)==3'>
          <button  class="next" @click='linklog'>去登录</button>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import logotitle from '@/components/logotitle/logotitle.vue';//图片标题组件
import verifystep from '@/components/verifystep/verifystep.vue';//图片标题组件
// import getcodebtn from '@/components/getcodebtn/getcodebtn.vue';//获取验证码按钮组件-types属性切换接口
import {mixin,setOrGetCode} from '../assets/js/common.js' //公用js
export default {
  name: 'verifysetresult',
  mixins: [mixin],//混入
  props: {
    msg: String
  },
  metaInfo() {
    return {
      title:this.htmltitle,
      meta: [
        {
          name: "description",
          content:
            "微微定wwding.com-纸巾定制上微微定 品质保障"
        },
        {
          name: "Keywords",
          content:
            "微微定wwding.com-纸巾定制上微微定 品质保障"
        }
      ],
    }
  },
  data(){
    return {
      htmltitle:'安全中心-微微定',
      password:null,
      passwords:null,
      errors:{
        password:null,
        pass:false,
        passwords:null,
        passs:false,
      }
    }
  },
  components: {
    logotitle,
    verifystep,
    // getcodebtn
  },
  methods:{
    linkSafe(){
      // 安全中心
      this._router({then:()=>{
        this.$router.push('/safecenter.html')
      },exp:true,catch:()=>{
        this.$router.push({path:'/accountlogin',query:{redirect:'/safecenter.html'}})
        // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    },
    linklog(){
      this._router({then:()=>{
        this.$router.push('/accountlogin.html')
      }})
    },
    // nexts(){
    //   // 下一步验证
    //   this.errors.password=null
    //   this.errors.passwords=null
    //   if(!this.password){
    //     this.errors.pass = true
    //     return this.errors.password = '请输入密码'
    //   }
    //   if(this.password.length<6||this.password.length>18){
    //     this.errors.pass = true
    //     return this.errors.password = '请输入，长度为6~18位的密码'
    //   }
    //   if(!this.passwords){
    //     this.errors.passs = true
    //     return this.errors.passwords = '请再次输入密码'
    //   }
    //   if(this.password!=this.passwords){
    //     this.errors.passs = true
    //     return this.errors.passwords = '两次密码不一致！'
    //   }
    //   // 成功跳转下一步
    // },
    removepas(v){
      // 去掉 红框
      // this.errors[v] = false
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // 加载
      var _this = this;
      let _pomise = this.$store.state.headPomise
      this._reduce([_pomise.a,_pomise.b])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            // this.$store.dispatch("cmtCap",true);//显示隐藏
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        let _footPomiseA = this.$store.state.footPomiseA
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseA,_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
    })
  },
  watch:{
  },
  filters:{

  }
}
</script>
<style  lang="scss" scoped>
.verifysetresult {
  padding-bottom:40px;
  .top {
    width: 100%;background: #fff;padding:10px 0px;box-shadow: 0px 3px 5px 0px rgba(212,212,212,0.4);position: relative;z-index: 9;
    .head {
      width: 1200px;margin:0 auto;
      .rechargestep {
        float:right;margin-top:5px;
      }
    }
  }
  .stepcnt {
    width: 100%;background: #fff;padding-top:25px;padding-bottom:130px;
    .verifystep {
      margin-bottom:70px;
    }
    .verifybox {
      width: 380px;margin:0 auto;
      .icon {
        margin-bottom:30px;
        span {
          display: block;width: 50px;height: 50px;background: #cccccc;border-radius: 50%;margin:0 auto;line-height: 50px;text-align: center;
          svg {
            width: 35px;height: 35px;vertical-align: -13px;fill: #fff;
          }
        }
        h6 {
          text-align: center;height: 35px;line-height: 35px;font-size: 14px;
        }
      }
      .next {
        width: 380px;height: 48px;border-radius: 5px;background: #f44e55;color:#fff;font-size: 16px;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    .pas {
      .icon {
        span {
          background: #5fddb2;
          svg {
            vertical-align: -15px;margin-left:2px;
          }
        }
      }
    }
  }
}
</style>
