<!-- 身份验证步骤 -->
<template>
  <div class="verifystep">
    <div class="steps" :class='["steps1","steps2","steps3"][steps]'>
      <div class="step">
        1
        <p class="info">身份验证</p>
      </div>
      <div class="step">
        2
        <p class="info">{{["设置支付密码","修改支付密码","修改登录密码","找回密码"][this._spf(this.$route.query.types)]}}</p>
      </div>
      <div class="step">
        3
        <p class="info">完成</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'verifystep',
  props: {
    steps: Number
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.verifystep {
  width: 610px;height: 80px;margin:0 auto;padding:10px 0px;
  .steps {
    display: flex;justify-content: space-between;position: relative;
    .step {
      width: 36px;height: 36px;background: #f5f5f5;border-radius: 50%;display: flex;justify-content: center;align-items: center;font-size: 14px;font-weight: bold;position: relative;z-index: 3;position: relative;
      .info {
        position: absolute;width: 100px;bottom:-30px;text-align: center;font-weight: normal;font-size: 14px;color:#404041;
      }
      &:after {
        content: '';position: absolute;width: 36px;height: 36px;border:5px solid #f54e63;border-radius: 50%;opacity: 0.2;display: none;
      }
    }
    &:after {
      content: '';position: absolute;display: block;width: 600px;height: 12px;background: #f5f5f5;top:12px;left:5px;z-index: 1;
    }
    &:before {
      content: '';position: absolute;display: block;width: 0px;height: 12px;top:12px;left:5px;z-index: 2;display: none;
    }
  }
  .steps1 {
    .step {
      &:nth-child(1) {
        background: #f54e63;color:#fff;
        &:after {
          display: block;
        }
      }
    }
    &:before {
      display: block;width: 200px;background-image: linear-gradient(to right, #f54e63 , #f5f5f5);
    }
  }
  .steps2 {
    .step {
      &:nth-child(-n+2) {
        background: #f54e63;color:#fff;
        &:after {
          display: block;
        }
      }
    }
    &:before {
      display: block;width: 520px;background-image: linear-gradient(to right, #f54e63 , #f5f5f5);
    }
  }
  .steps3 {
    .step {
      &:nth-child(-n+3) {
        background: #f54e63;color:#fff;
        &:after {
          display: block;
        }
      }
    }
    &:before {
      display: block;width: 600px;background:#f54e63;
    }
  }
}
</style>
